import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faShoppingCart, faBox, faBoxOpen, faChartBar, faUser, faBars,
  faSortDown, faExclamationTriangle, faTachometerAlt, faBell, faQrcode,
  faLongArrowAltRight, faMoneyBillAlt, faDatabase, faSmileWink,
  faSearch, faTimes, faPlus, faList, faBalanceScale, faTrashAlt,
  faFileInvoice, faDownload, faUpload, faFolderOpen, faHandHoldingUsd
} from '@fortawesome/free-solid-svg-icons'

library.add({
  faShoppingCart, faBox, faBoxOpen,  faChartBar, faUser, faBars,
  faSortDown, faExclamationTriangle, faTachometerAlt, faBell, faQrcode,
  faLongArrowAltRight, faMoneyBillAlt, faDatabase, faSmileWink,
  faSearch, faTimes, faPlus, faList, faBalanceScale, faTrashAlt,
  faFileInvoice, faDownload, faUpload, faFolderOpen, faHandHoldingUsd
})

Vue.component('fa', FontAwesomeIcon)

window.axios = axios;

Vue.mixin({
  methods: {
    isNumeric(x) {
      let str = x.toString();
      if (str.match(/^[0-9]+$/)) return true;
      return false;
    },
    logOut(x) {
      if(confirm("Voulez-vous vraiment deconnecter?")){
        this.$store.state.user = null
        this.$store.state.active_kiosk = null
      }
    },
    money(x) {
      let cash = parseFloat(x).toFixed(0)
      if(!x) return "0";
      return cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    cleanString(str){
      if (!str) return "";
      if(typeof(str)=='object'){
        let string = ""
        for( let [clef, valeur] of Object.entries(str)){
          if(typeof(valeur)=='object'){
            let child = ""
            for( let [key, value] of Object.entries(valeur)){
              child += `- ${key}: ${value}. `
            }
            valeur = child;
          }
          string+=`${clef}: ${valeur}. `
        }
        return string;
      };
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
    },
    getGroupName(id){
      let group = this.$store.state.groups.find(x => x.id == id)
      return !!group? group.name:"-";
    },
    getGroupId(name){
      let group = this.$store.state.groups.find(x => {
        return x.name.toLowerCase() == name.toLowerCase()
      })
      return !!group? group.id:-1;
    },
    userIs(personnel, group_id){
      let groups = personnel.user.groups;
      return groups.includes(group_id);
    },
    datetime(x) {
      if(!x) return "-"
      let date = new Date(x);
      return new Intl.DateTimeFormat(
        'en-GB',
        { dateStyle: 'short', timeStyle: 'short' }
      ).format(date)
    },
    displayErrorOrRefreshToken(error, callback){
      if(!!error.response){
        if(error.response.data.code == "token_not_valid"){ 
          let refresh = this.$store.state.user.refresh
          if(!refresh){
            this.$store.state.user = null;
            return
          }
          axios.post(this.url+"/refresh/", {"refresh":refresh})
          .then((response) => {
            this.$store.state.user.access = response.data.access
            if(typeof callback == "function") callback()
          }).catch((error) => {
            this.$store.state.user = null;
            console.error(error)
            this.$store.state.alert = {
              type:"danger", message:this.cleanString(error.response.data)
            }
          })
        } else {
          this.$store.state.alert = {
            type:"danger", message:this.cleanString(error.response.data)
          }
        }
      }
    },
    refreshOBR(callback){
      let username = this.active_kiosk.username
      let password = this.active_kiosk.password
      if(!username || !password){
        this.$store.state.obr_user = null;
        return
      }
      let data = {
        "username": username,
        "password": password
      }
      axios.post(this.obr_url+"/login/", JSON.stringify(data))
      .then((response) => {
        this.$store.state.obr_token = response.data.result.token
        callback()
      }).catch((error) => {
        this.$store.state.obr_token = null;
        console.error(error)
      })
    },
    displayErrorOrRefreshOBR(error, callback, elseCallback){
      if(!!error.response){
        if(error.response.status == 403){
          this.refreshOBR(callback)
        } else {
          this.$store.state.alert = {
            type:"danger", message:this.cleanString(error.response.data.msg)
          }
          if(typeof(elseCallback) == 'function'){
            elseCallback()
          }
        }
      } else {
        console.error(error)
      }
    },
    getActiveKiosk(){
      if(!this.$store.state.active_kiosk){
        if(!!this.active_user.kiosks && this.active_user.kiosks.length == 1){
          this.$store.state.active_kiosk = this.active_user.kiosks[0]
        }
      }
      return this.$store.state.active_kiosk
    },
    commandToInvoice(invoice_data) {
      let date = new Date().toISOString().replace("T", " ").split(".")[0];
      let str_date = date.replaceAll(":", "").replaceAll("-", "").replaceAll(" ", "");
      let year = new Date().getFullYear();
    
      let kiosk = this.active_kiosk;
      let data = JSON.parse(JSON.stringify(invoice_data));
      let invoice_number = `${data.id}/${year}`;
      let client = data.client;
    
      data["invoice_date"] = date;
      data["cancelled_invoice_ref"] = this.cancelled_invoice_ref;
      data["invoice_number"] = invoice_number;
      data["invoice_identifier"] = `${kiosk.tp_TIN}/${kiosk.username}/${str_date}/${invoice_number}`;
      data["invoice_items"] = data["ventes"];
    
      data["vat_taxpayer"] = client.vat_taxpayer;
      data["ct_taxpayer"] = client.ct_taxpayer;
      data["tl_taxpayer"] = client.tl_taxpayer;
      data["payment_type"] = client.payment_type;
      data["invoice_currency"] = client.invoice_currency;
      data["customer_name"] = client.customer_name;
      data["customer_TIN"] = client.customer_TIN;
      data["customer_address"] = client.customer_address;
      data["vat_customer_payer"] = client.vat_customer_payer;
      data["tel"] = client.tel;
    
      data["tp_type"] = kiosk.tp_type;
      data["tp_name"] = kiosk.tp_name;
      data["tp_TIN"] = kiosk.tp_TIN;
      data["tp_trade_number"] = kiosk.tp_trade_number;
      data["tp_address_commune"] = kiosk.tp_address_commune;
      data["tp_address_quartier"] = kiosk.tp_address_quartier;
      data["tp_fiscal_center"] = kiosk.tp_fiscal_center;
      data["tp_activity_sector"] = kiosk.tp_activity_sector;
    
      delete data["ventes"];
      delete data["payee"];
      delete data["kiosk"];
      delete data["client"];
    
      return JSON.stringify(data);
    },
  },
  computed:{
    active_user(){
      return this.$store.state.user;
    },
    active_kiosk(){
      return this.getActiveKiosk();
    },
    active_user_fullname(){
      return `${this.active_user.first_name} ${this.active_user.last_name}`;
    },
    user_is_owner(){
      return !!this.getActiveKiosk() && this.active_user.owned.includes(this.getActiveKiosk().id)
    },
    base_url(){
      return this.$store.state.base_url;
    },
    url(){
      return this.base_url + this.$store.state.api;
    },
    obr_url(){
      return this.base_url + this.$store.state.obr_api;
    },
    obr_headers(){
      return {
        headers:{
          "Authorization":"Bearer "+this.$store.state.obr_token,
          "Content-Type":"application/json, text/plain",
        }
      }
    },
    headers(){
      return {
        headers:{
          "Authorization":"Bearer "+this.$store.state.user.access
        }
      }
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
