import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Magasin from '../views/Magasin.vue'
import Users from '../views/Users.vue'
import Notifications from '../views/Notifications.vue'
import Notification from '../views/Notification.vue'
import Stock from '../views/Stock.vue'
import Pertes from '../views/Pertes.vue'
import Ventes from '../views/Ventes.vue'
import StatDettes from '../views/StatDettes.vue'
import Paiements from '../views/Paiements.vue'
import StatProduits from '../views/StatProduits.vue'
import StatClient from '../views/StatClient.vue'
import POS from '../views/POS.vue'
import Produit from '../views/Produit.vue'
import Versement from '../views/Versement.vue'
import Upload from '../views/Upload.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home},
  { path: '/pos', name: 'pos', component: POS},
  { path: '/magasin', name: 'magasin', component: Magasin},
  { path: '/magasin/edit', name: 'magasinedit', component: Magasin},
  { path: '/magasin/upload', name: 'Upload', component: Upload},
  { path: '/users', name: 'users', component: Users},
  { path: '/notifs', name: 'notifications', component: Notifications},
  { path: '/notifs/:id', name: 'notification', component: Notification},
  { path: '/stock', name: 'stock', component: Stock},
  { path: '/pertes', name: 'pertes', component: Pertes},
  { path: '/ventes', name: 'ventes', component: Ventes},
  { path: '/produits', name: 'produit', component: Produit},
  { path: '/stat_dettes', name: 'statdettes', component: StatDettes},
  { path: '/paiements', name: 'paiements', component: Paiements},
  { path: '/stat_produits', name: 'statproduits', component: StatProduits},
  { path: '/stat_clients', name: 'statclient', component: StatClient},
  { path: '/versements', name: 'versement', component: Versement},
]

const router = new VueRouter({
  routes
})

export default router
