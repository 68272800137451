<template>
	<StatsLayout>
		<div class="option">
			<label for="dette">dettes</label>
			<input type="checkbox" id="dette" v-model="only_dettes">
			<button
				class="sync_button"
				@click="synchroniser">
				Synchroniser
			</button>
		</div>
		<div class="table nonprintable">
		<table>
			<thead>
				<tr>
					<th></th>
					<th>No.</th>
					<th>date</th>
					<th>caissier</th>
					<th>client</th>
					<th class="right">somme</th>
					<th class="right">reste</th>
					<th class="right">benefice</th>
					<th>options</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in commandes"
					:class="{'syncing':item.syncing}">
					<td class="sync">
						<fa v-if="!item.synced" icon="exclamation-triangle"/>
					</td>
					<td>{{ item.id }}</td>
					<td>{{ datetime(item.date) }}</td>
					<td>{{ item.user }}</td>
					<td>{{ getUser(item) }}</td>
					<td class="right">{{ money(item.prix) }} FBu</td>
					<td class="right">{{ money(item.prix-item.payee) }} FBu</td>
					<td class="right">{{ money(item.prix-item.prix_achat) }} FBu</td>
					<td class="left">
						<button @click="showDetails(item)">
							details
						</button>
						<button class="red" @click="supprimer(item)">
							Supprimer
						</button>
						<button v-if="item.payee < item.prix" @click="payer(item)">
							Payer
						</button>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th colspan="5"></th>
					<th class="right">{{ money(
						commandes.reduce((acc, x) => {
							return acc + x.prix
						}, 0)
					)}} FBu
					</th>
					<th class="right">{{ money(
						commandes.reduce((acc, x) => {
							return acc + (x.prix - x.payee)
						}, 0)
					)}} FBu
					</th>
					<th class="right">{{ money(
						commandes.reduce((acc, x) => {
							return acc + (x.prix - x.prix_achat)
						}, 0)
					)}} FBu
					</th>
					<th colspan="2"></th>
				</tr>
			</tfoot>
		</table>
		</div>
		<DialogVentes :active="ventes_shown" :commande="active_command" @close="close"/>
		<DialogPayment :active="payment_shown" :commande="active_command" @close="close"/>
	</StatsLayout>
</template>
<script>
import StatsLayout from "./stats_layout"
import DialogVentes from "../components/dialog_ventes"
import DialogPayment from "../components/dialog_payment"

export default{
	components:{ StatsLayout, DialogVentes, DialogPayment},
	data(){
		return{
			ventes_shown:false,
			active_command:null,
			next:null,
			commandes:this.$store.state.commandes,
			payment_shown:false,
			only_dettes:false,
			unsynceds:[]
		}
	},
	watch:{
		"$store.state.commandes"(new_val){
			this.commandes = new_val
			this.unsynceds = new_val.filter(x => !x.synced)
		},
		only_dettes(new_val){
			if(new_val){
				this.commandes = this.$store.state.commandes.filter(x =>{
					return x.prix > x.payee
				})
			} else {
				this.commandes = this.$store.state.commandes
			}
		}
	},
	methods:{
		close(){
			this.ventes_shown = false
			this.active_command = null
			this.payment_shown = false
		},
		getUser(item){
			if(typeof item.client == "string"){
				return item.client
			} else {
				return `${item.client.nom} ${item.client.tel}`
			}
		},
		showDetails(commande){
			this.ventes_shown = true
			this.active_command = commande
		},
		payer(commande){
			this.payment_shown = true
			this.active_command = commande
		},
		synchroniser(){
			console.log('Syncronisation', this.unsynceds.length)
			if(this.unsynceds.length == 0) return
			let item = this.unsynceds[0]
      let index = this.commandes.findIndex(x => x.id == item.id)
      this.commandes[index].syncing = true
			axios.get(`${this.url}/commande/${item.id}/`, this.headers)
			.then((response) => {
				this.performOBRPost(response.data, index)
			}).catch((error) => {
			  console.error(error);
			})
		},
		performOBRPost(data, index){
      let invoice = this.commandToInvoice(data)
      axios.post(this.obr_url+"/addInvoice/", invoice, this.obr_headers)
      .then((response) => {
      	let success = false
        if(!response.data.success){
          if(response.data.msg.includes("API est manquante.")){
            this.refreshOBR(() => this.performOBRPost(data, index))
          } else if(response.data.msg.includes("existe déjà.")){
          	success = true
          } else {
            this.$store.state.alert = {
              type:"danger", message:this.cleanString(response.data.msg)
            }
          }
        } else {
        	success = true
        }
        if(success){
					console.log(index)
					if(!index) return
        	this.saveResults(index)
        }
      }).catch((error) => {
        this.displayErrorOrRefreshOBR(error, () => this.performOBRPost(data, index), null)
      })
		},
		saveResults(index){
    	this.unsynceds.shift()
    	this.commandes[index].syncing = false
    	this.$store.state.commandes[index].synced = true
    	this.commandes[index].synced = true
      this.synchroniser()
		},
		supprimer(item){
			if(confirm("Voulez-vous vraiment annuler cette commande?")){
				axios.delete(`${this.url}/commande/${item.id}/`, this.headers)
				.then((response) => {
					let index = this.$store.state.commandes.indexOf(item)
					this.$store.state.commandes.splice(index, 1)
				}).catch((error) => {
					this.displayErrorOrRefreshToken(error, () => this.supprimer(item))
				});
			}
		},
		fetchData(){
			let link = ""
			let kiosk_id = this.getActiveKiosk().id
			if(!this.next){
				link = this.url+`/commande/?kiosk=${kiosk_id}`;
			} else {
				link = this.next
			}
			axios.get(link, this.headers)
			.then((response) => {
				this.$store.state.commandes.push(...response.data.results)
				if(response.data.next.length > 0){
					this.next = response.data.next
					this.fetchData()
				} else {
					this.next = null
				}
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchData)
			});
		},
	},
	mounted(){
		if(this.$store.state.commandes.length<1){
			this.fetchData()
		}
	}
};
</script>
<style scoped>
.option{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
#dette{
	margin-left: 5px;
}
.table{
  height: calc(100vh - 125px);
}
.sync{
	font-size: .7em;
	padding: 0;
	color: #750;
}
.sync_button{
	padding: 2px 10px;
	margin: 0 0 0 10px;
}
.syncing{
	color: blue;
}
</style>