<template>
<div class="printable invoice">
    <center class="header">
        <img width=100 :src="getActiveKiosk().logo" style="display:block" />
        <div v-if="commande">
            Facture no. {{commande.id}} du {{datetime(commande.date)}}<br>
        </div>
        <div style="text-align:left;display:inline-block;margin-right:15px;">
            {{ getActiveKiosk().nom }}<br>
            RC 23606/20<br>
            NIF 4001409707<br>
            {{ getActiveKiosk().tel }}<br>
        </div>
        <div v-if="commande"
            style="text-align:left;display:inline-block;">
            {{ commande.customer_name }}<br>
            {{ commande.tel }}<br>
            {{ commande.customer_address }}<br>
            {{ commande.customer_TIN }}<br>
        </div>
    </center>
    <table style="width:100%;">
        <tbody v-if="commande">
            <tr style="border-bottom: 1px solid #aaa;">
                <th style="text-align: left;">Article</th>
                <th style="text-align: right;">P.U</th>
                <th style="text-align: center;">Qt.</th>
                <th style="text-align: right;">Total</th>
            </tr>
            <tr style="text-align: left;" v-for="item in commande.ventes">
                <td>{{ item.produit }}</td>
                <td style="text-align: right;">{{ money(item.prix_unitaire) }}</td>
                <td style="text-align: center;">x {{ item.quantite }}</td>
                <td style="text-align: right;">{{ money(item.prix_total) }} Fbu</td>
            </tr>
            <tr style="border-top: 1px solid #aaa;text-align: left;">
                <th colspan="3">Total</th>
                <th style="text-align: right;"><b>{{ money(commande.prix) }} Fbu</b></th>
            </tr>
        </tbody>
    </table>
    <div style="margin:10px" v-if="commande">
        Caissier: {{ commande.user }}
    </div>
    <center>
    	<strong>Murakoze, Merci, Thank you!</strong>
    </center>
</div>
</template>
<script>
export default {
	props:["commande"],
    data(){
        return {
            ventes:[]
        }
    }
};
</script>
<style scoped>
*{
	margin: 0;
    font-family: consolas;
}
.header *{
	margin: 10px 0;
}
@media print{
    .invoice{
        background-color: white;
        position: fixed;
        width: 100%;
    }
}
</style>